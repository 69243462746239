import React, {useEffect} from 'react'
import {useLazyQuery} from "@apollo/client";
import {GET_ONE_BUSINESS_CARD_BY_UUID} from "../../graphql/businessCardGraphQL";
import SetupForm from "../../components/businessCards/setupForm";
import {useTranslation} from "react-i18next";
import logo from "../../images/logo.png";
import dancingVideo from "../../videos/dancing.mp4";

export default function Setup() {
    const { t } = useTranslation()

    let uuid = ""
    if (typeof window !== 'undefined') {
        uuid = window.location.pathname.split("/").pop()
    }

    //loading
    const [loadBusinessCard, { queryLoading, queryError, data }] = useLazyQuery(GET_ONE_BUSINESS_CARD_BY_UUID )

    useEffect(() => {
        loadBusinessCard( {
            variables: {
                uuid: uuid
            }
        })
    }, []);

    if (queryLoading) {
        console.log(queryLoading)
    }

    if (queryError != null) {
        console.log(queryError)
    }

   if (data === undefined) {
       return (<div>
           Loading
       </div>)
   }

    return (
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('business.setup.title')}</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('business.setup.subtitle')}
                        </p>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <SetupForm data={data}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
