import {gql} from "@apollo/client/core";

export const GET_ONE_BUSINESS_CARD_BY_UUID = gql`
query ($uuid: String){
  businessCards (
    where: {
      UUID: $uuid
    }
    limit: 1
  ) {
    id
    UUID
    Logo {
      url
    }
    BusinessName
    FirstName
    LastName
    Position
    Email
    Phone
    CellPhone
    AddressLine1
    AddressLine2
    Zip
    City
    WebsiteURL
    TwitterURL
    GitHubURL
    XingURL
    LinkedInURL
  }
}`
export const UPDATE_BUSINESS_CARD_BY_UUID = gql `
mutation (
  $id: ID!
  $logoId: ID
  $businessName: String
  $lastName: String
  $firstName: String
  $position: String
  $email: String
  $phone: String
  $cellPhone: String
  $addressLine1: String
  $zip: String
  $city: String
  $twitterURL: String
  $gitHubURL: String
  $linkedInURL: String
  $xingURL: String
  $websiteURL: String
) {
  updateBusinessCard(
    input: { 
    where: { id: $id } 
    data: { 
    BusinessName: $businessName
    Logo: $logoId
    LastName: $lastName
    FirstName: $firstName
    Position: $position
    Email: $email
    Phone: $phone
    CellPhone: $cellPhone
    AddressLine1: $addressLine1
    Zip: $zip
    City: $city
    TwitterURL: $twitterURL
    GitHubURL: $gitHubURL
    LinkedInURL: $linkedInURL
    XingURL: $xingURL
    WebsiteURL: $websiteURL
  } }) {
    businessCard {
      id
    }
  }
}
`