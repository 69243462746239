import React, {useRef, useState} from 'react'
import {BriefcaseIcon, DeviceMobileIcon, GlobeAltIcon, HomeIcon, MailIcon, PhoneIcon} from "@heroicons/react/solid";
import {useMutation} from "@apollo/client";
import {UPDATE_BUSINESS_CARD_BY_UUID} from "../../graphql/businessCardGraphQL";
import {useTranslation} from "react-i18next";
import {MUTATION_UPLOAD} from "../../graphql/uploadGraphQL";

export default function SetupForm(prop) {
    const { t } = useTranslation()
    let data = prop.data

    const [businessName, setBusinessName] = useState(data.businessCards[0].BusinessName !== undefined ? data.businessCards[0].BusinessName : "")
    const [firstName, setFirstName] = useState(data.businessCards[0].FirstName !== undefined ? data.businessCards[0].FirstName : "")
    const [lastName, setLastName] = useState(data.businessCards[0].LastName !== undefined ? data.businessCards[0].LastName : "")
    const [logoId, setLogoId] = useState(null);
    const [logoURL,setLogoURL] = useState(data.businessCards[0].Logo !== null ? data.businessCards[0].Logo.url : "");
    const [position, setPosition] = useState(data.businessCards[0].Position !== undefined ? data.businessCards[0].Position : "")
    const [email, setEmail] = useState(data.businessCards[0].Email !== undefined ? data.businessCards[0].Email : "")
    const [phone, setPhone] = useState(data.businessCards[0].Phone !== undefined ? data.businessCards[0].Phone : "")
    const [cellPhone, setCellPhone] = useState(data.businessCards[0].CellPhone !== undefined ? data.businessCards[0].CellPhone : "")
    const [addressLine1, setAddressLine1] = useState(data.businessCards[0].AddressLine1 !== undefined ? data.businessCards[0].AddressLine1 : "")
    const [zip, setZip] = useState(data.businessCards[0].Zip !== undefined ? data.businessCards[0].Zip : "")
    const [city, setCity] = useState(data.businessCards[0].City !== undefined ? data.businessCards[0].City : "")
    const [websiteURL, setWebsiteURL] = useState(data.businessCards[0].WebsiteURL !== undefined ? data.businessCards[0].WebsiteURL : "")
    const [twitterURL, setTwitterURL] = useState(data.businessCards[0].TwitterURL !== undefined ? data.businessCards[0].TwitterURL : "")
    const [gitHubURL, setGitHubURL] = useState(data.businessCards[0].GitHubURL !== undefined ? data.businessCards[0].GitHubURL : "")
    const [linkedInURL, setLinkedInURL] = useState(data.businessCards[0].LinkedInURL !== undefined ? data.businessCards[0].LinkedInURL : "")
    const [xingURL, setXingURL] = useState(data.businessCards[0].XingURL !== undefined ? data.businessCards[0].XingURL : "")

    const inputLogoImage = useRef(null);

    const [
        updateBusinessCard,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(UPDATE_BUSINESS_CARD_BY_UUID)

    const [
        uploadImage,
        {loading: mutationUploadLoading, error: mutationUploadError},
    ] = useMutation(MUTATION_UPLOAD)

    const handleLogoPreviewUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            uploadImage({
                variables: {
                    file: files[0]
                }
            }).then((res) => {
                setLogoId(res.data.upload.id)
                setLogoURL(res.data.upload.url)
            })
        }
    };

    const onButtonLogoPreview = () => {
        inputLogoImage.current.click();
    };

    //saving
    const handleSetup = async (event) => {
        // Block native form submission.
        event.preventDefault()

        updateBusinessCard({
            variables: {
                id: data.businessCards[0].id,
                logoId: logoId,
                businessName: businessName,
                lastName: lastName,
                firstName: firstName,
                position: position,
                email: email,
                phone: phone,
                cellPhone: cellPhone,
                addressLine1: addressLine1,
                zip: zip,
                city: city,
                twitterURL: twitterURL,
                gitHubURL: gitHubURL,
                linkedInURL: linkedInURL,
                xingURL: xingURL,
                websiteURL: websiteURL
            },
        }).then()
    }

    if (mutationError != undefined) {
        console.log(mutationError)
    }

    if (mutationUploadError != undefined) {
        console.log(mutationUploadError)
    }

    return (
        <form
            onSubmit={handleSetup}
            className="space-y-6">

            <div>
                <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.logo')}
                </label>
                {!mutationUploadLoading && (
                    <div className="mt-2 flex items-center">
                                                <span id="logoPreview" className="inline-block h-12 w-12 rounded-full
                                                overflow-hidden bg-gray-100">
                                                    {!logoURL &&(
                                                        <svg className="h-full w-full text-gray-300" fill="currentColor"
                                                             viewBox="0 0 24 24">
                                                            <path
                                                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004
                                                                15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4
                                                                4 0 11-8 0 4 4 0 018 0z"/>
                                                        </svg>
                                                    )}
                                                    {logoURL && (
                                                        <img
                                                            src={(process.env.NODE_ENV === 'development' ? "http://localhost:1337" : "" ) + logoURL}
                                                            className="h-full w-full text-gray-300"
                                                        />
                                                    )}
                                                </span>
                        <input
                            style={{ display: "none" }}
                            accept=".png,.jpg,.svg"
                            ref={inputLogoImage}
                            onChange={handleLogoPreviewUpload}
                            type="file"
                        />
                        <button type="button"
                                onClick={onButtonLogoPreview}
                                className="ml-5 bg-white py-2 px-3 border border-gray-300
                                                        rounded-md shadow-sm text-sm leading-4 font-medium
                                                        text-gray-700 hover:bg-gray-50 focus:outline-none
                                                        focus:ring-2 focus:ring-offset-2 focus:ring-logoColorTwo">
                            {t('business.setup.logo.button')}
                        </button>
                    </div>
                )}

            </div>
            <div>
                <label htmlFor="business" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.companyName')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <BriefcaseIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="business"
                        id="business"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.companyName.placeholder')}
                        value={businessName}
                        onChange={(e) => {
                            setBusinessName(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                        {t('business.setup.firstName')}
                    </label>
                    <div className="mt-1">
                        <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            autoComplete="firstName"
                            required
                            placeholder={t('business.setup.firstName.placeholder')}
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                        {t('business.setup.lastName')}
                    </label>
                    <div className="mt-1">
                        <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            autoComplete="lastName"
                            required
                            placeholder={t('business.setup.lastName.placeholder')}
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="position" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.position')}
                </label>
                <div className="mt-1">
                    <input
                        id="position"
                        name="position"
                        type="text"
                        autoComplete="position"
                        required
                        placeholder={t('business.setup.position.placeholder')}
                        value={position}
                        onChange={(e) => {
                            setPosition(e.target.value)
                        }}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.email')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.email.placeholder')}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.phone')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.phone.placeholder')}
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="cellPhone" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.cellphone')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <DeviceMobileIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="cellPhone"
                        id="cellPhone"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.cellphone.placeholder')}
                        value={cellPhone}
                        onChange={(e) => {
                            setCellPhone(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.addressLine1')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <HomeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="addressLine1"
                        id="addressLine1"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.addressLine1.placeholder')}
                        value={addressLine1}
                        onChange={(e) => {
                            setAddressLine1(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                        {t('business.setup.zip')}
                    </label>
                    <div className="mt-1">
                        <input
                            id="zip"
                            name="zip"
                            type="text"
                            autoComplete="zip"
                            placeholder={t('business.setup.zip.placeholder')}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={zip}
                            onChange={(e) => {
                                setZip(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        {t('business.setup.city')}
                    </label>
                    <div className="mt-1">
                        <input
                            id="city"
                            name="city"
                            type="text"
                            autoComplete="city"
                            placeholder={t('business.setup.city.placeholder')}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.website')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <GlobeAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="website"
                        id="webiste"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.website.placeholder')}
                        value={websiteURL}
                        onChange={(e) => {
                            setWebsiteURL(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.twitter')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                            width={14}
                            height={14}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        >
                            <path
                                d="M7.548 22.501c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 5.305a9.828 9.828 0 01-2.828.776 4.94 4.94 0 002.165-2.724 9.867 9.867 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.491A13.98 13.98 0 011.67 3.9a4.928 4.928 0 001.525 6.573A4.887 4.887 0 01.96 9.855v.063a4.926 4.926 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 010 20.289a13.941 13.941 0 007.548 2.209"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <input
                        type="text"
                        name="twitter"
                        id="twitter"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.twitter.placeholder')}
                        value={twitterURL}
                        onChange={(e) => {
                            setTwitterURL(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="github" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.github')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        >
                            <path
                                d="M11.999 0C5.373 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.386.6.11.819-.26.819-.578 0-.284-.01-1.04-.017-2.04-3.337.724-4.042-1.61-4.042-1.61-.545-1.386-1.332-1.755-1.332-1.755-1.09-.744.082-.73.082-.73 1.205.086 1.838 1.238 1.838 1.238 1.07 1.833 2.81 1.304 3.493.996.109-.775.419-1.303.762-1.603C7.145 17 4.343 15.97 4.343 11.373c0-1.31.468-2.382 1.236-3.22-.124-.304-.536-1.524.118-3.176 0 0 1.007-.323 3.3 1.23.956-.266 1.983-.4 3.003-.404 1.02.005 2.046.138 3.005.404 2.29-1.553 3.296-1.23 3.296-1.23.655 1.652.243 2.872.12 3.176.77.838 1.233 1.91 1.233 3.22 0 4.61-2.806 5.624-5.478 5.921.43.37.814 1.103.814 2.223 0 1.603-.015 2.898-.015 3.291 0 .321.217.695.825.578C20.565 21.796 24 17.3 24 12c0-6.627-5.373-12-12.001-12"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <input
                        type="text"
                        name="github"
                        id="github"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.github.placeholder')}
                        value={gitHubURL}
                        onChange={(e) => {
                            setGitHubURL(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.linkedin')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                            className="h-5 w-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path fillRule="evenodd"
                                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                  clipRule="evenodd">
                            </path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.linkedin.placeholder')}
                        value={linkedInURL}
                        onChange={(e) => {
                            setLinkedInURL(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="xing" className="block text-sm font-medium text-gray-700">
                    {t('business.setup.xing')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                            className="h-5 w-5 text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path fillRule="evenodd"
                                  d="M14.887 24l-5.324-9.667 8.07-14.333h4.933l-8.069 14.333 5.27 9.667h-4.88zm-7.291-19h-4.939l2.768 4.744-4.115 7.256h4.914l4.117-7.271-2.745-4.729z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        name="xing"
                        id="xing"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('business.setup.linkedin.placeholder')}
                        value={xingURL}
                        onChange={(e) => {
                            setXingURL(e.target.value)
                        }}
                    />
                </div>
            </div>

            <div>
                <button
                    disabled={mutationLoading}
                    type="submit"
                    className={ (mutationLoading ? 'bg-indigo-300' : 'bg-indigo-600' ) + " w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                >
                    {mutationLoading && (
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    )}
                    {t('business.setup.button')}
                </button>
            </div>
        </form>
    )
}
